import React from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { mobile } from 'styles/breakpoints';

const Container = styled.div`
  padding: 1rem;
`;

const StyledTitle = styled(Text)`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
  font-family: Inter;
  @media ${mobile} {
    font-size: 2rem;
  }
`;

const StyledHeading = styled(Text)`
  font-weight: 600;
  font-family: Inter;
  margin: 1rem 0;
  font-size: 1.25rem;
`;

const StyledText = styled(Text)`
  font-family: Inter;
  margin-bottom: 0.5rem;
  color: #28293d;
  font-weight: 300;
  letter-spacing: -0.024em;
  a:any-link {
    color: #0088ff;
  }
`;

const References = () => (
  <Container>
    <StyledTitle>References</StyledTitle>

    <StyledHeading>Chapter 1. Beating Procrastination</StyledHeading>
    <StyledText>
      Steel, P. (2007).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/17201571/"
        target="_blank"
        rel="noreferrer"
      >
        The nature of procrastination: A meta-analytic and theoretical review of
        quintessential self-regulatory failure.{' '}
      </a>
      Psychological Bulletin, 133(1), 65-94.
    </StyledText>
    <StyledText>
      Ferrari, J. R., O&apos;Callaghan, J., &amp; Newbegin, I. (2005).{' '}
      <a
        href="https://psycnet.apa.org/record/2005-03779-001"
        target="_blank"
        rel="noreferrer"
      >
        Prevalence of procrastination in the United States, United Kingdom, and
        Australia: Arousal and avoidance delays among adults.
      </a>{' '}
      North American Journal of Psychology, 7(1), 1-6.
    </StyledText>
    <StyledText>
      Pychyl, T. A., &amp; Flett, G. L. (2012).{' '}
      <a
        href="https://link.springer.com/article/10.1007/s10942-012-0149-5"
        target="_blank"
        rel="noreferrer"
      >
        Procrastination and self-regulatory failure: An introduction to the
        special issue.{' '}
      </a>
      Journal of Rational-Emotive &amp; Cognitive-Behavior Therapy, 30(4),
      203-212.
    </StyledText>
    <StyledText>
      Sirois, F. M. (2014).{' '}
      <a
        href="https://self-compassion.org/wp-content/uploads/publications/Procrastination.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Procrastination and stress: Exploring the role of self-compassion.
      </a>{' '}
      Self and Identity, 13(2), 128-145.
    </StyledText>
    <StyledText>
      Tice, D. M., &amp; Baumeister, R. F. (1997).
      <a
        href="https://journals.sagepub.com/doi/10.1111/j.1467-9280.1997.tb00460.x"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Longitudinal study of procrastination, performance, stress, and health:
        The costs and benefits of dawdling.
      </a>{' '}
      Psychological Science, 8(6), 454-458.
    </StyledText>
    <StyledText>
      Sirois, F. M., &amp; Pychyl, T. (2013).{' '}
      <a
        href="https://eprints.whiterose.ac.uk/91793/1/Compass%20Paper%20revision%20FINAL.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Procrastination and the priority of short-term mood regulation:
        Consequences for future self.
      </a>{' '}
      Social and Personality Psychology Compass, 7(2), 115-127.
    </StyledText>
    <StyledText>
      Clear, J. (2018).{' '}
      <a
        href="https://jamesclear.com/atomic-habits"
        target="_blank"
        rel="noreferrer"
      >
        Atomic habits: An easy &amp; proven way to build good habits &amp; break
        bad ones.
      </a>{' '}
      Avery.
    </StyledText>
    <StyledText>
      Duhigg, C. (2012).{' '}
      <a
        href="https://psycnet.apa.org/record/2012-09134-000"
        target="_blank"
        rel="noreferrer"
      >
        The power of habit: Why we do what we do in life and business.
      </a>{' '}
      Random House.
    </StyledText>
    <StyledText>
      Walker, M. (2017).{' '}
      <a
        href="https://www.simonandschuster.com/books/Why-We-Sleep/Matthew-Walker/9781501144325"
        target="_blank"
        rel="noreferrer"
      >
        Why we sleep: Unlocking the power of sleep and dreams.{' '}
      </a>
      Scribner.
    </StyledText>
    <StyledText>
      Ellis, A., &amp; Knaus, W. J. (1977).{' '}
      <a
        href="https://www.scirp.org/(S(351jmbntvnsjt1aadkposzje))/reference/ReferencesPapers.aspx?ReferenceID=883211"
        target="_blank"
        rel="noreferrer"
      >
        Overcoming procrastination.
      </a>{' '}
      Institute for Rational Living.
    </StyledText>
    <StyledText>
      Oaten, M., &amp; Cheng, K. (2006).
      <a
        href="https://www.tandfonline.com/doi/abs/10.1207/s15324834basp2801_1"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Improved self‐control: The benefits of a regular program of academic
        study.
      </a>{' '}
      Basic and Applied Social Psychology, 28(1), 1-16.
    </StyledText>
    <StyledHeading>Chapter 2. Signs of Procrastination</StyledHeading>
    <StyledText>
      Cavanagh, K., Strauss, C., Cicconi, F., Griffiths, N., Wyper, A., &amp;
      Jones, F. (2013).{' '}
      <a
        href="https://linkinghub.elsevier.com/retrieve/pii/S0005-7967(13)00114-9"
        target="_blank"
        rel="noreferrer"
      >
        A randomised controlled trial of a brief online mindfulness-based
        intervention.{' '}
      </a>
      Behaviour Research and Therapy, 51(9), 573-578.
    </StyledText>
    <StyledText>
      Steel, P. (2007).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/17201571/"
        target="_blank"
        rel="noreferrer"
      >
        The nature of procrastination: A meta-analytic and theoretical review of
        quintessential self-regulatory failure.{' '}
      </a>
      Psychological Bulletin, 133(1), 65-94.
    </StyledText>
    <StyledText>
      Tuckman, B. W. (1991).{' '}
      <a
        href="https://journals.sagepub.com/doi/10.1177/0013164491512022"
        target="_blank"
        rel="noreferrer"
      >
        The development and concurrent validity of the Procrastination Scale.
      </a>{' '}
      Educational and Psychological Measurement, 51(2), 473-480.
    </StyledText>
    <StyledText>
      Britton, B. K., &amp; Tesser, A. (1991).{' '}
      <a
        href="https://psycnet.apa.org/record/1992-10822-001"
        target="_blank"
        rel="noreferrer"
      >
        Effects of time-management practices on college grades.
      </a>{' '}
      Journal of Educational Psychology, 83(3), 405.
    </StyledText>
    <StyledText>
      Claessens, B. J., van Eerde, W., Rutte, C. G., &amp; Roe, R. A. (2007).
      <a
        href="https://www.emerald.com/insight/content/doi/10.1108/00483480710726136/full/html"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        A review of the time management literature.
      </a>{' '}
      Personnel Review.
    </StyledText>
    <StyledText>
      Carriere, J. S., Cheyne, J. A., &amp; Smilek, D. (2008).{' '}
      <a
        href="https://www.sciencedirect.com/science/article/abs/pii/S1053810007000323?via%3Dihub"
        target="_blank"
        rel="noreferrer"
      >
        Everyday attention lapses and memory failures: The affective
        consequences of mindlessness.
      </a>{' '}
      Consciousness and Cognition, 17(3), 835-847.
    </StyledText>
    <StyledText>
      Eastwood, J. D., Frischen, A., Fenske, M. J., &amp; Smilek, D. (2012).{' '}
      <a
        href="https://journals.sagepub.com/doi/10.1177/1745691612456044"
        target="_blank"
        rel="noreferrer"
      >
        The unengaged mind: Defining boredom in terms of attention.
      </a>{' '}
      Perspectives on Psychological Science, 7(5), 482-495.
    </StyledText>
    <StyledText>
      van Eerde, W. (2003).{' '}
      <a
        href="https://www.tandfonline.com/doi/abs/10.1080/00223980309600625"
        target="_blank"
        rel="noreferrer"
      >
        Procrastination at work and time management training.
      </a>{' '}
      The Journal of Psychology, 137(5), 421-434.
    </StyledText>
    <StyledText>
      Tice, D. M., &amp; Baumeister, R. F. (1997).{' '}
      <a
        href="https://journals.sagepub.com/doi/10.1111/j.1467-9280.1997.tb00460.x"
        target="_blank"
        rel="noreferrer"
      >
        Longitudinal study of procrastination, performance, stress, and health:
        The costs and benefits of dawdling.{' '}
      </a>
      Psychological Science, 8(6), 454-458.
    </StyledText>
    <StyledText>
      Broadbent, D. E., Cooper, P. F., FitzGerald, P., &amp; Parkes, K. R.
      (1982).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/7126941/"
        target="_blank"
        rel="noreferrer"
      >
        The cognitive failures questionnaire (CFQ) and its correlates.
      </a>{' '}
      British Journal of Clinical Psychology, 21(1), 1-16.
    </StyledText>
    <StyledText>
      Jha, A. P., Krompinger, J., &amp; Baime, M. J. (2007).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/17672382/"
        target="_blank"
        rel="noreferrer"
      >
        Mindfulness training modifies subsystems of attention.{' '}
      </a>
      Cognitive, Affective, &amp; Behavioral Neuroscience, 7(2), 109-119.
    </StyledText>
    <StyledHeading>Chapter 3. Overcoming procrastination habit</StyledHeading>
    <StyledText>
      Baumeister, R. F., &amp; Vohs, K. D. (2007).{' '}
      <a
        href="https://compass.onlinelibrary.wiley.com/doi/abs/10.1111/j.1751-9004.2007.00001.x"
        target="_blank"
        rel="noreferrer"
      >
        Self-regulation, ego depletion, and motivation. Social and Personality
        Psychology Compass,
      </a>{' '}
      1(1), 115-128.
    </StyledText>
    <StyledText>
      Tice, D. M., &amp; Baumeister, R. F. (1997).{' '}
      <a
        href="https://journals.sagepub.com/doi/10.1111/j.1467-9280.1997.tb00460.x"
        target="_blank"
        rel="noreferrer"
      >
        Longitudinal study of procrastination, performance, stress, and health:
        The costs and benefits of dawdling.{' '}
      </a>
      Psychological Science, 8(6), 454-458.
    </StyledText>
    <StyledText>
      Sirois, F. M. (2014).{' '}
      <a
        href="https://self-compassion.org/wp-content/uploads/publications/Procrastination.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Procrastination and stress: Exploring the role of self-compassion.
      </a>{' '}
      Self and Identity, 13(2), 128-145.
    </StyledText>
    <StyledText>
      Atkinson, J. W., &amp; Raynor, J. O. (1974).{' '}
      <a
        href="https://journals.sagepub.com/doi/abs/10.1177/001316447503500131"
        target="_blank"
        rel="noreferrer"
      >
        Motivation and achievement.
      </a>{' '}
      Washington: Winston.
    </StyledText>
    <StyledText>
      Steel, P. (2007).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/17201571/"
        target="_blank"
        rel="noreferrer"
      >
        The nature of procrastination: A meta-analytic and theoretical review of
        quintessential self-regulatory failure.
      </a>{' '}
      Psychological Bulletin, 133(1), 65-94.
    </StyledText>
    <StyledText>
      Covey, S. R. (1994).{' '}
      <a
        href="https://www.simonandschuster.com/books/The-7-Habits-of-Highly-Effective-People/Stephen-R-Covey/9781982137137"
        target="_blank"
        rel="noreferrer"
      >
        The 7 habits of highly effective people: Powerful lessons in personal
        change.{' '}
      </a>
      New York: Simon &amp; Schuster.
    </StyledText>
    <StyledText>
      Locke, E. A., &amp; Latham, G. P. (2006).{' '}
      <a
        href="https://psycnet.apa.org/record/2006-21929-013"
        target="_blank"
        rel="noreferrer"
      >
        New directions in goal-setting theory.
      </a>{' '}
      Current Directions in Psychological Science, 15(5), 265-268.
    </StyledText>
    <StyledText>
      Twenge, J. M., &amp; Campbell, W. K. (2018).{' '}
      <a
        href="https://www.sakkyndig.com/psykologi/artvit/twenge2009.pdf"
        target="_blank"
        rel="noreferrer"
      >
        The narcissism epidemic: Living in the age of entitlement.
      </a>{' '}
      New York: Atria Books.
    </StyledText>
    <StyledText>
      Alter, A. (2017).{' '}
      <a
        href="https://books.google.lt/books/about/Irresistible.html?id=SKOeDAEACAAJ&redir_esc=y"
        target="_blank"
        rel="noreferrer"
      >
        Irresistible: The rise of addictive technology and the business of
        keeping us hooked.{' '}
      </a>
      New York: Penguin Press.
    </StyledText>
    <StyledText>
      Odaci, H., &amp; Kalkan, M. (2010).{' '}
      <a
        href="https://www.sciencedirect.com/science/article/abs/pii/S0360131510001387"
        target="_blank"
        rel="noreferrer"
      >
        Problematic Internet use, loneliness and dating anxiety among young
        adult university students.
      </a>{' '}
      Computers &amp; Education, 55(3), 1091-1097.
    </StyledText>
    <StyledHeading>Chapter 4. Focus and concentration</StyledHeading>
    <StyledText>
      Baumeister, R. F., Vohs, K. D., &amp; Tice, D. M. (2007).{' '}
      <a
        href="https://journals.sagepub.com/doi/10.1111/j.1467-8721.2007.00534.x"
        target="_blank"
        rel="noreferrer"
      >
        The strength model of self-control. Current Directions in Psychological
        Science,{' '}
      </a>
      16(6), 351-355.
    </StyledText>
    <StyledText>
      Gazzaley, A., &amp; Rosen, L. D. (2016).{' '}
      <a
        href="https://mitpress.mit.edu/9780262534437/the-distracted-mind/"
        target="_blank"
        rel="noreferrer"
      >
        The distracted mind: Ancient brains in a high-tech world.{' '}
      </a>
      MIT Press.
    </StyledText>
    <StyledText>
      Zeigarnik, B. (1967).{' '}
      <a
        href="https://www.codeblab.com/wp-content/uploads/2009/12/On-Finished-and-Unfinished-Tasks.pdf"
        target="_blank"
        rel="noreferrer"
      >
        On finished and unfinished tasks.
      </a>{' '}
      In W. D. Ellis (Ed.), A sourcebook of Gestalt psychology (pp. 300-314).
      New York: Humanities press.
    </StyledText>
    <StyledText>
      Kabat-Zinn, J. (2003).{' '}
      <a
        href="https://psycnet.apa.org/record/2003-03824-002"
        target="_blank"
        rel="noreferrer"
      >
        Mindfulness-based interventions in context: Past, present, and future.{' '}
      </a>
      Clinical Psychology: Science and Practice, 10(2), 144-156.
    </StyledText>
    <StyledText>
      Mark, G., Voida, S., &amp; Cardello, A. (2012).{' '}
      <a
        href="https://www.researchgate.net/publication/239761293_A_pace_not_dictated_by_electrons_An_empirical_study_of_work_without_email"
        target="_blank"
        rel="noreferrer"
      >
        &quot;A pace not dictated by electrons&quot;: An empirical study of work
        without email.{' '}
      </a>
      Proceedings of the SIGCHI Conference on Human Factors in Computing
      Systems, 555-564.
    </StyledText>
    <StyledText>
      Vohs, K. D., Redden, J. P., &amp; Rahinel, R. (2013).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/23907542/"
        target="_blank"
        rel="noreferrer"
      >
        Physical order produces healthy choices, generosity, and
        conventionality, whereas disorder produces creativity.
      </a>{' '}
      Psychological Science, 24(9), 1860-1867.
    </StyledText>
    <StyledText>
      Tang, Y. Y., &amp; Posner, M. I. (2009).{' '}
      <a
        href="https://www.cell.com/trends/cognitive-sciences/fulltext/S1364-6613(09)00065-5?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS1364661309000655%3Fshowall%3Dtrue"
        target="_blank"
        rel="noreferrer"
      >
        Attention training and attention state training.
      </a>{' '}
      Trends in Cognitive Sciences, 13(5), 222-227.
    </StyledText>
    <StyledText>
      Park, D. C., Lodi-Smith, J., Drew, L., Haber, S., Hebrank, A., Bischof, G.
      N., &amp; Aamodt, W. (2014).{' '}
      <a
        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4154531/"
        target="_blank"
        rel="noreferrer"
      >
        The impact of sustained engagement on cognitive function in older
        adults: The Synapse Project.
      </a>{' '}
      Psychological Science, 25(1), 103-112.
    </StyledText>
    <StyledText>
      Ariga, A., &amp; Lleras, A. (2011).{' '}
      <a
        href="https://www.sciencedirect.com/science/article/abs/pii/S0010027710002994?via%3Dihub"
        target="_blank"
        rel="noreferrer"
      >
        Brief and rare mental “breaks” keep you focused: Deactivation and
        reactivation of task goals preempt vigilance decrements.{' '}
      </a>
      Cognition, 118(3), 439-443.
    </StyledText>
    <StyledHeading>Chapter 5. Productivity</StyledHeading>
    <StyledText>
      Walker, M. P. (2017).{' '}
      <a
        href="https://www.simonandschuster.com/books/Why-We-Sleep/Matthew-Walker/9781501144325"
        target="_blank"
        rel="noreferrer"
      >
        Why we sleep: Unlocking the power of sleep and dreams.
      </a>{' '}
      Simon and Schuster.
    </StyledText>
    <StyledText>
      Foster, R. G., &amp; Kreitzman, L. (2014).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/24363383/"
        target="_blank"
        rel="noreferrer"
      >
        The rhythms of life: What your body clock means to you!.
      </a>{' '}
      Experimental Physiology, 99(12), 599-606.
    </StyledText>
    <StyledText>
      Sapolsky, R. M. (2004).{' '}
      <a
        href="https://www.scirp.org/(S(351jmbntvnsjt1aadkposzje))/reference/ReferencesPapers.aspx?ReferenceID=670241"
        target="_blank"
        rel="noreferrer"
      >
        Why zebras don&apos;t get ulcers: The acclaimed guide to stress,
        stress-related diseases, and coping.
      </a>{' '}
      Holt paperbacks.
    </StyledText>
    <StyledText>
      Arendt, J. (2005).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/16077149/"
        target="_blank"
        rel="noreferrer"
      >
        Melatonin: characteristics, concerns, and prospects.
      </a>{' '}
      Journal of Biological Rhythms, 20(4), 291-303.
    </StyledText>
    <StyledText>
      Oldham, G. R., &amp; Brass, D. J. (1979).{' '}
      <a
        href="https://www.semanticscholar.org/paper/Employee-Reactions-to-an-Open-Plan-Office%3A-A-Oldham-Brass/fa3f4ffc687cc1e879cfd59f1fe88ba6088962a1"
        target="_blank"
        rel="noreferrer"
      >
        Employee reactions to an open‐plan office: A naturally occurring
        quasi‐experiment
      </a>
      . Administrative Science Quarterly, 267-284.
    </StyledText>
    <StyledText>
      Vischer, J. C. (2007).{' '}
      <a
        href="https://www.researchgate.net/publication/227520676_The_effects_of_the_physical_environment_on_job_performance_Towards_a_theoretical_model_of_workspace_stress"
        target="_blank"
        rel="noreferrer"
      >
        The effects of the physical environment on job performance: towards a
        theoretical model of workspace stress.
      </a>{' '}
      Stress and Health: Journal of the International Society for the
      Investigation of Stress, 23(3), 175-184.
    </StyledText>
    <StyledText>
      Salvucci, D. D., &amp; Taatgen, N. A. (2008).
      <a
        href="https://psycnet.apa.org/record/2008-00265-005"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Threaded cognition: An integrated theory of concurrent multitasking.
      </a>{' '}
      Psychological review, 115(1), 101.
    </StyledText>
    <StyledText>
      Ophir, E., Nass, C., &amp; Wagner, A. D. (2009).{' '}
      <a
        href="https://www.pnas.org/doi/10.1073/pnas.0903620106"
        target="_blank"
        rel="noreferrer"
      >
        Cognitive control in media multitaskers.{' '}
      </a>
      Proceedings of the National Academy of Sciences, 106(37), 15583-15587.
    </StyledText>
    <StyledText>
      Särkämö, T., Tervaniemi, M., Laitinen, S., Forsblom, A., Soinila, S.,
      Mikkonen, M., ... &amp; Hietanen, M. (2008).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/18287122/"
        target="_blank"
        rel="noreferrer"
      >
        Music listening enhances cognitive recovery and mood after a middle
        cerebral artery stroke.{' '}
      </a>
      Brain, 131(3), 866-876.
    </StyledText>
    <StyledText>
      Haake, A. B. (2011).{' '}
      <a
        href="https://www.academia.edu/296501/Individual_music_listening_in_workplace_settings_an_exploratory_survey_of_offices_in_the_UK"
        target="_blank"
        rel="noreferrer"
      >
        Individual music listening in the workplace: A study on the effect of
        free-play music listening on perceived task load, concentration, and
        productivity.{' '}
      </a>
      Psychology of Music, 39(1), 129-147.
    </StyledText>
    <StyledText>
      Cirillo, F. (2006).{' '}
      <a
        href="http://friend.ucsd.edu/reasonableexpectations/downloads/Cirillo%20--%20Pomodoro%20Technique.pdf"
        target="_blank"
        rel="noreferrer"
      >
        The Pomodoro Technique: The Life-Changing Time-Management System.
      </a>{' '}
      Penguin.
    </StyledText>
    <StyledText>
      Baumeister, R. F., &amp; Vohs, K. D. (Eds.). (2004).{' '}
      <a
        href="https://psycnet.apa.org/record/2004-00163-000"
        target="_blank"
        rel="noreferrer"
      >
        Handbook of self-regulation: Research, theory, and applications.{' '}
      </a>
      Guilford Press.
    </StyledText>
    <StyledText>
      Clear, J. (2018).{' '}
      <a
        href="https://jamesclear.com/atomic-habits"
        target="_blank"
        rel="noreferrer"
      >
        Atomic habits: An easy &amp; proven way to build good habits &amp; break
        bad ones.
      </a>{' '}
      Avery.
    </StyledText>
    <StyledHeading>Chapter 6. Procrastination Triggers</StyledHeading>
    <StyledText>
      Dweck, C. (2006).
      <a
        href="https://www.scirp.org/(S(351jmbntvnsjt1aadkposzje))/reference/ReferencesPapers.aspx?ReferenceID=1850818"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Mindset: The new psychology of success.
      </a>{' '}
      Random House Incorporated.
    </StyledText>
    <StyledText>
      Atkinson, J. W. (1957).{' '}
      <a
        href="https://psycnet.apa.org/record/1959-03029-001"
        target="_blank"
        rel="noreferrer"
      >
        Motivational determinants of risk-taking behavior.
      </a>{' '}
      Psychological Review, 64(5), 359.
    </StyledText>
    <StyledText>
      Schwartz, B. (2004).{' '}
      <a
        href="https://works.swarthmore.edu/fac-psychology/198/"
        target="_blank"
        rel="noreferrer"
      >
        The paradox of choice: Why more is less.
      </a>{' '}
      Ecco.
    </StyledText>
    <StyledText>
      Eastwood, J. D., Frischen, A., Fenske, M. J., &amp; Smilek, D. (2012).{' '}
      <a
        href="https://psycnet.apa.org/record/2012-24579-005"
        target="_blank"
        rel="noreferrer"
      >
        The unengaged mind: Defining boredom in terms of attention.{' '}
      </a>
      Perspectives on Psychological Science, 7(5), 482-495.
    </StyledText>
    <StyledText>
      Csikszentmihalyi, M. (1990).
      <a
        href="https://www.researchgate.net/publication/224927532_Flow_The_Psychology_of_Optimal_Experience"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Flow: The psychology of optimal experience.
      </a>{' '}
      Harper &amp; Row.
    </StyledText>
    <StyledText>
      Horner, M. S. (1968).{' '}
      <a
        href="https://www.worldcat.org/title/sex-differences-in-achievement-motivation-and-performance-in-competitive-and-non-competitive-situations/oclc/10652238"
        target="_blank"
        rel="noreferrer"
      >
        Sex differences in achievement motivation and performance in competitive
        and non-competitive situations.
      </a>{' '}
      Unpublished doctoral dissertation, University of Michigan.
    </StyledText>
    <StyledText>
      Pappas, J. T., &amp; Friedman, H. S. (2007).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/957090/"
        target="_blank"
        rel="noreferrer"
      >
        The construct of fear of success: A historical analysis.{' '}
      </a>
      Journal of Career Assessment, 15(1), 5-21.
    </StyledText>
    <StyledText>
      Wood, R. E. (1986).{' '}
      <a
        href="https://www.sciencedirect.com/science/article/abs/pii/0749597886900440"
        target="_blank"
        rel="noreferrer"
      >
        Task complexity: Definition of the construct.{' '}
      </a>
      Organizational Behavior and Human Decision Processes, 37(1), 60-82.
    </StyledText>
    <StyledText>
      Rosenzweig, P. (2007).{' '}
      <a
        href="https://www.sciencedirect.com/science/article/abs/pii/0749597886900440"
        target="_blank"
        rel="noreferrer"
      >
        The halo effect: ... and the eight other business delusions that deceive
        managers.
      </a>{' '}
      Free Press.
    </StyledText>
    <StyledText>
      Steel, P. (2007).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/17201571/"
        target="_blank"
        rel="noreferrer"
      >
        The nature of procrastination: A meta-analytic and theoretical review of
        quintessential self-regulatory failure.
      </a>{' '}
      Psychological Bulletin, 133(1), 65.
    </StyledText>
    <StyledText>
      Locke, E. A., &amp; Latham, G. P. (1990).{' '}
      <a
        href="https://www.researchgate.net/publication/232501090_A_Theory_of_Goal_Setting_Task_Performance"
        target="_blank"
        rel="noreferrer"
      >
        A theory of goal setting &amp; task performance.
      </a>{' '}
      Prentice-Hall, Inc.
    </StyledText>
    <StyledText>
      Maule, A. J., Hockey, G. R., &amp; Bdzola, L. (2000).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/10900697/"
        target="_blank"
        rel="noreferrer"
      >
        Effects of time-pressure on decision-making under uncertainty: Changes
        in affective state and information processing strategy.
      </a>{' '}
      Acta Psychologica, 104(3), 283-301.
    </StyledText>
    <StyledText>
      Cirillo, F. (2006).{' '}
      <a
        href="http://friend.ucsd.edu/reasonableexpectations/downloads/Cirillo%20--%20Pomodoro%20Technique.pdf"
        target="_blank"
        rel="noreferrer"
      >
        The Pomodoro Technique: The Life-Changing Time-Management System.
      </a>{' '}
      Penguin.
    </StyledText>
    <StyledHeading>Chapter 7. Nutrition</StyledHeading>
    <StyledText>
      Steel, P. (2007).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/17201571/"
        target="_blank"
        rel="noreferrer"
      >
        The nature of procrastination: a meta-analytic and theoretical review of
        quintessential self-regulatory failure.
      </a>{' '}
      Psychological bulletin, 133(1), 65.
    </StyledText>
    <StyledText>
      Drewnowski, A., &amp; Almiron-Roig, E. (2010).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/21452463/"
        target="_blank"
        rel="noreferrer"
      >
        Human perceptions and preferences for fat-rich foods.
      </a>{' '}
      In Montmayeur JP, le Coutre J (Eds.), Fat Detection: Taste, Texture, and
      Post Ingestive Effects (pp. 265-290). CRC Press/Taylor &amp; Francis.
    </StyledText>
    <StyledText>
      Gibson, E. L. (2007).{' '}
      <a
        href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1467-3010.2007.00606.x"
        target="_blank"
        rel="noreferrer"
      >
        Carbohydrates and mental function: feeding or impeding the brain?
      </a>
      . Nutrition Bulletin, 32(s1), 71-83.
    </StyledText>
    <StyledText>
      Slavin, J. L., &amp; Lloyd, B. (2012).
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/22797986/"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Health benefits of fruits and vegetables.{' '}
      </a>
      Advances in Nutrition, 3(4), 506-516.
    </StyledText>
    <StyledText>
      Popkin, B. M., D’Anci, K. E., &amp; Rosenberg, I. H. (2010).{' '}
      <a
        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2908954/"
        target="_blank"
        rel="noreferrer"
      >
        Water, hydration, and health.
      </a>{' '}
      Nutrition reviews, 68(8), 439-458.
    </StyledText>
    <StyledText>
      Swanson, D., Block, R., &amp; Mousa, S. A. (2012).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/22332096/"
        target="_blank"
        rel="noreferrer"
      >
        Omega-3 fatty acids EPA and DHA: health benefits throughout life.
      </a>{' '}
      Advances in Nutrition, 3(1), 1-7.
    </StyledText>
    <StyledText>
      Tribole, E., &amp; Resch, E. (2012).
      <a
        href="https://www.researchgate.net/publication/303988534_Tribole_E_and_E_Resch_2012_Intuitive_Eating_3rd_edition_St_Martin's_Press_NYNY"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Intuitive eating, 3rd edition.
      </a>{' '}
      St. Martin&apos;s Griffin.
    </StyledText>
    <StyledText>
      Cryan, J. F., &amp; Dinan, T. G. (2012).{' '}
      <a
        href="https://www.nature.com/articles/nrn3346"
        target="_blank"
        rel="noreferrer"
      >
        Mind-altering microorganisms: the impact of the gut microbiota on brain
        and behaviour.{' '}
      </a>
      Nature reviews neuroscience, 13(10), 701-712.
    </StyledText>
    <StyledText>
      Borota, D., Murray, E., Keceli, G., Chang, A., Watabe, J. M., Ly, M., ...
      &amp; Yassa, M. A. (2014).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/24413697/"
        target="_blank"
        rel="noreferrer"
      >
        Post-study caffeine administration enhances memory consolidation in
        humans.{' '}
      </a>
      Nature neuroscience, 17(2), 201-203.
    </StyledText>
    <StyledHeading>Chapter 8. Sleep</StyledHeading>
    <StyledText>
      Walker, M. (2017).{' '}
      <a
        href="https://www.simonandschuster.com/books/Why-We-Sleep/Matthew-Walker/9781501144325"
        target="_blank"
        rel="noreferrer"
      >
        Why we sleep: Unlocking the power of sleep and dreams.{' '}
      </a>
      Scribner.
    </StyledText>
    <StyledText>
      Altena, E., Baglioni, C., Espie, C. A., Ellis, J., Gavriloff, D.,
      Holzinger, B., ... &amp; Riemann, D. (2020).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/32246787/"
        target="_blank"
        rel="noreferrer"
      >
        Dealing with sleep problems during home confinement due to the COVID‐19
        outbreak: Practical recommendations from a task force of the European
        CBT‐I Academy
      </a>
      . Journal of Sleep Research.
    </StyledText>
    <StyledText>
      Kroese, F. M., De Ridder, D. T., Evers, C., &amp; Adriaanse, M. A. (2014).{' '}
      <a
        href="https://www.frontiersin.org/articles/10.3389/fpsyg.2014.00611/full"
        target="_blank"
        rel="noreferrer"
      >
        Bedtime procrastination: introducing a new area of procrastination.
      </a>{' '}
      Frontiers in psychology, 5, 611.
    </StyledText>
    <StyledText>
      Scott, H., &amp; Biello, S. M. (2019).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/21678170/"
        target="_blank"
        rel="noreferrer"
      >
        Sleep hygiene behaviours: an application of the theory of planned
        behaviour and the investigation of perceived autonomy support, past
        behaviour and response inhibition.{' '}
      </a>
      Psychology &amp; Health.
    </StyledText>
    <StyledText>
      Sarris, J., Byrne, G. J., Cribb, L., Oliver, G., Murphy, J., Macdonald,
      P., ... &amp; Bousman, C. (2019).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/30580081/"
        target="_blank"
        rel="noreferrer"
      >
        L-theanine in the adjunctive treatment of generalized anxiety disorder:
        A double-blind, randomised, placebo-controlled trial.{' '}
      </a>
      Journal of Psychiatric Research.
    </StyledText>
    <StyledText>
      Abbasi, B., Kimiagar, M., Sadeghniiat, K., Shirazi, M. M., Hedayati, M.,
      &amp; Rashidkhani, B. (2012).{' '}
      <a
        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3703169/"
        target="_blank"
        rel="noreferrer"
      >
        The effect of magnesium supplementation on primary insomnia in elderly:
        A double-blind placebo-controlled clinical trial.
      </a>{' '}
      Journal of research in medical sciences: the official journal of Isfahan
      University of Medical Sciences, 17(12), 1161.
    </StyledText>
    <StyledText>
      Bernstein, D. A., &amp; Borkovec, T. D. (1973).{' '}
      <a
        href="https://psycnet.apa.org/record/1974-11691-000"
        target="_blank"
        rel="noreferrer"
      >
        Progressive relaxation training: A manual for the helping professions.{' '}
      </a>
      Research Press.
    </StyledText>
    <StyledText>
      Varvogli, L., &amp; Darviri, C. (2011).{' '}
      <a
        href="https://www.itmedicalteam.pl/articles/stress-management-techniques-evidencebased-procedures-that-reduce-stress-and-promote-health.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Stress Management Techniques: evidence-based procedures that reduce
        stress and promote health
      </a>
      . Health science journal, 5(2), 74.
    </StyledText>
    <StyledText>
      Ma, X., Yue, Z. Q., Gong, Z. Q., Zhang, H., Duan, N. Y., Shi, Y. T., ...
      &amp; Li, Y. F. (2017).{' '}
      <a
        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5455070/"
        target="_blank"
        rel="noreferrer"
      >
        The effect of diaphragmatic breathing on attention, negative affect and
        stress in healthy adults.
      </a>{' '}
      Frontiers in psychology, 8, 874.
    </StyledText>
    <StyledText>
      Jerath, R., Edry, J. W., Barnes, V. A., &amp; Jerath, V. (2006).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/16624497/"
        target="_blank"
        rel="noreferrer"
      >
        Physiology of long pranayamic breathing: Neural respiratory elements may
        provide a mechanism that explains how slow deep breathing shifts the
        autonomic nervous system.
      </a>{' '}
      Medical hypotheses, 67(3), 566-571.
    </StyledText>
    <StyledHeading>Chapter 9. Physical activity</StyledHeading>
    <StyledText>
      Steel, P. (2007). The nature of procrastination: A meta-analytic and
      theoretical review of quintessential self-regulatory failure.
      Psychological Bulletin, 133(1), 65-94.
    </StyledText>
    <StyledText>
      Sirois, F. M., &amp; Kitner, R. (2015).{' '}
      <a
        href="https://psycnet.apa.org/record/2015-10096-001"
        target="_blank"
        rel="noreferrer"
      >
        Less adaptive or more maladaptive? A meta-analytic investigation of
        procrastination and coping.{' '}
      </a>
      European Journal of Personality, 29(4), 433-444.
    </StyledText>
    <StyledText>
      Ratey, J. J., &amp; Loehr, J. E. (2011).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/21417955/"
        target="_blank"
        rel="noreferrer"
      >
        The positive impact of physical activity on cognition during adulthood:
        a review of underlying mechanisms, evidence, and recommendations.{' '}
      </a>
      Reviews in the Neurosciences, 22(2), 171-185.
    </StyledText>
    <StyledText>
      Dishman, R. K., Berthoud, H. R., Booth, F. W., Cotman, C. W., Edgerton, V.
      R., Fleshner, M. R., ... &amp; Ploughman, M. (2006).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/16648603/"
        target="_blank"
        rel="noreferrer"
      >
        Neurobiology of exercise.
      </a>{' '}
      Obesity, 14(3), 345-356.
    </StyledText>
    <StyledText>
      Smith, J. C., Paulson, E. S., Cook, D. B., Verber, M. D., &amp; Tian, Q.
      (2010).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/20603148/"
        target="_blank"
        rel="noreferrer"
      >
        Detecting changes in human cerebral blood flow after acute exercise
        using arterial spin labeling: implications for fMRI.
      </a>{' '}
      Journal of Neuroscience Methods, 191(2), 258-262.
    </StyledText>
    <StyledText>
      Ekkekakis, P., Parfitt, G., &amp; Petruzzello, S. J. (2011).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/21780850/"
        target="_blank"
        rel="noreferrer"
      >
        The pleasure and displeasure people feel when they exercise at different
        intensities: Decennial update and progress towards a tripartite
        rationale for exercise intensity prescription.
      </a>{' '}
      Sports Medicine, 41(8), 641-671.
    </StyledText>
    <StyledText>
      O’Connor, P. J., Herring, M. P., &amp; Caravalho, A. (2010).{' '}
      <a
        href="https://journals.sagepub.com/doi/abs/10.1177/1559827610368771"
        target="_blank"
        rel="noreferrer"
      >
        Mental health benefits of strength training in adults
      </a>
      . American Journal of Lifestyle Medicine, 4(5), 377-396.
    </StyledText>
    <StyledText>
      Gothe, N. P., &amp; McAuley, E. (2015).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/26186435/"
        target="_blank"
        rel="noreferrer"
      >
        Yoga and cognition: A meta-analysis of chronic and acute effects.{' '}
      </a>
      Psychosomatic Medicine, 77(7), 784-797.
    </StyledText>
    <StyledText>
      Hüfner, K., Binetti, C., Hamilton, D. A., Stephan, T., Flanagin, V. L.,
      Linn, J., ... &amp; Strupp, M. (2011).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/20572197/"
        target="_blank"
        rel="noreferrer"
      >
        Structural and functional plasticity of the hippocampal formation in
        professional dancers and slackliners.
      </a>{' '}
      Hippocampus, 21(8), 855-865.
    </StyledText>
    <StyledText>
      Eime, R. M., Young, J. A., Harvey, J. T., Charity, M. J., &amp; Payne, W.
      R. (2013).{' '}
      <a
        href="https://ijbnpa.biomedcentral.com/articles/10.1186/1479-5868-10-98"
        target="_blank"
        rel="noreferrer"
      >
        A systematic review of the psychological and social benefits of
        participation in sport for adults: informing development of a conceptual
        model of health through sport.
      </a>{' '}
      The International Journal of Behavioral Nutrition and Physical Activity,
      10(1), 135.
    </StyledText>
    <StyledHeading>Chapter 10. Mindfulness</StyledHeading>
    <StyledText>
      Kabat-Zinn, J. (1994).{' '}
      <a
        href="https://www.scirp.org/(S(lz5mqp453edsnp55rrgjct55))/reference/ReferencesPapers.aspx?ReferenceID=845294"
        target="_blank"
        rel="noreferrer"
      >
        Wherever you go, there you are: Mindfulness meditation in everyday life.
      </a>{' '}
      Hyperion.
    </StyledText>
    <StyledText>
      Baer, R. A. (2003).{' '}
      <a
        href="https://psycnet.apa.org/record/2003-03824-001"
        target="_blank"
        rel="noreferrer"
      >
        Mindfulness training as a clinical intervention: A conceptual and
        empirical review.{' '}
      </a>
      Clinical Psychology: Science and Practice, 10(2), 125-143.
    </StyledText>
    <StyledText>
      Tang, Y. Y., Hölzel, B. K., &amp; Posner, M. I. (2015).{' '}
      <a
        href="https://www.nature.com/articles/nrn3916"
        target="_blank"
        rel="noreferrer"
      >
        The neuroscience of mindfulness meditation.
      </a>{' '}
      Nature Reviews Neuroscience, 16(4), 213-225.
    </StyledText>
    <StyledText>
      Lehrer, P. M., &amp; Gevirtz, R. (2014).
      <a
        href="https://www.frontiersin.org/articles/10.3389/fpsyg.2014.00756/full"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Heart rate variability biofeedback: how and why does it work?.
      </a>{' '}
      Frontiers in Psychology, 5, 756.
    </StyledText>
    <StyledText>
      Brown, R. P., &amp; Gerbarg, P. L. (2005).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/16131297/"
        target="_blank"
        rel="noreferrer"
      >
        Sudarshan Kriya yogic breathing in the treatment of stress, anxiety, and
        depression.
      </a>{' '}
      Journal of Clinical Psychiatry, 66(4), 511-512.
    </StyledText>
    <StyledText>
      Farb, N. A., Segal, Z. V., &amp; Anderson, A. K. (2013).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/22689216/"
        target="_blank"
        rel="noreferrer"
      >
        Mindfulness meditation training alters cortical representations of
        interoceptive attention.
      </a>{' '}
      Social Cognitive and Affective Neuroscience, 8(1), 15-26.
    </StyledText>
    <StyledText>
      Desbordes, G., et al. (2012).{' '}
      <a
        href="https://www.frontiersin.org/articles/10.3389/fnhum.2012.00292/full"
        target="_blank"
        rel="noreferrer"
      >
        Effects of mindful-attention and compassion meditation training on
        amygdala response to emotional stimuli in an ordinary, non-meditative
        state.
      </a>{' '}
      Frontiers in Human Neuroscience, 6, 292
    </StyledText>
    <StyledText>
      Thich Nhat Hanh. (1996).
      <a
        href="https://terebess.hu/zen/mesterek/Thich%20Nhat%20Hanh%20-%20The%20Miracle%20of%20Mindfulness.pdf"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        The Miracle of Mindfulness: An Introduction to the Practice of
        Meditation.{' '}
      </a>
      Beacon Press.
    </StyledText>
    <StyledText>
      Kerr, C. E., et al. (2017).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/21501665/"
        target="_blank"
        rel="noreferrer"
      >
        Effects of mindfulness meditation on spontaneous and task-evoked alpha
        rhythm.
      </a>{' '}
      Mindfulness, 8(2), 286-297.
    </StyledText>
    <StyledText>
      Dimidjian, S., Hollon, S. D., Dobson, K. S., Schmaling, K. B., Kohlenberg,
      R. J., Addis, M. E., ... &amp; Jacobson, N. S. (2006).{' '}
      <a
        href="https://pubmed.ncbi.nlm.nih.gov/16881773/"
        target="_blank"
        rel="noreferrer"
      >
        Randomized trial of behavioral activation, cognitive therapy, and
        antidepressant medication in the acute treatment of adults with major
        depression.
      </a>{' '}
      Journal of Consulting and Clinical Psychology, 74(4), 658.
    </StyledText>
  </Container>
);

export default References;
